<template>
    <div>
        <div class="is-cobalt-4 is-size-6 py-1" style="position:sticky; top:0px;"> 
            <span class="ml-4 has-text-light">{{$t('overview')}}</span>
        </div>
        <table class="table is-fullwidth is-narrow background-transparent">
            <thead class="has-text-grey-light materials-header">
                <!-- <tr>
                    <span class="has-text-weight-bold ml-3" style="color: #4e77ae">Monday, 03.01.2020</span>
                </tr> -->
                <tr class="is-size-7">
                    <th class="has-text-info-dark" width="15%">{{ $t("time") }}</th>
                    <th class="has-text-info-dark" width="20%">{{$t('truck')}} </th>
                    <th class="has-text-info-dark" width="15%">{{ $t("warehouse") }}</th>
                    <th class="has-text-info-dark" width="20%">{{ $t("contact") }}</th>
                    <!-- <th class="has-text-info-dark "></th> -->
                </tr>
            </thead>
            <tbody>
                <template v-for="(trucks, range) in all_trucks" >
                    <tr :key="range" class="has-text-white ">
                        <td colspan="4"  style="background: rgb(116, 159, 113);" class="is-size-6 py-0">
                            <b>{{range | customdate}}</b>
                            <span class="is-pulled-right">
                                <i class="fas fa-angle-down"></i>
                            </span>
                        </td>
                    </tr>
                    <tr v-for="truck in true ? trucks.sort((a, b) => new Date(a.arrives_at) - new Date(b.arrives_at)) : []" :key="truck.guid" class="has-text-dark table_border" style="font-size:13px">
                        <td class="has-text-weight-bold">
                            <i title="Delivers to" class="fa fa-arrow-right" v-if="truck.direction == 0"></i>
                            <i title="Returns to" class="fa fa-arrow-left" v-else></i>
                            &nbsp;{{truck.arrives_at | time}}
                        </td>
                        <td>
                            {{truck.name}}
                        </td>
                        <td>
                            <span v-if="truck.origin_entity != 'venue'" :class="[truck.is_transit ? 'is-text-palete-blue-4 has-text-weight-bold' : '']">
                                {{truck.origin > 0 ? get_warehouse_name(truck.origin) : get_warehouse_name(truck.destination)}}
                            </span>
                            <span v-else-if="$parent.connected_projects['proj_' + truck.origin]" class="is-text-palete-blue-4 has-text-weight-bold">
                                {{$parent.connected_projects['proj_' + truck.origin]}} 
                            </span>

                            <span v-else-if="truck.connected_project_id" class="is-text-palete-blue-4 has-text-weight-bold">
								{{truck.project_name}}
                            </span>                        
						</td>
                        <td>
                            {{ $parent.get_param_name(truck.contact_person, $parent.project_parameters, ', ' ) }}
                        
                        </td>
                        <!-- <td>
                            {{ get_param_name(truck.destination, project_parameters, ' ', false) }}
                            
                        </td> -->
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    data(){
        return{
            is_transit: false,
            all_trucks: {}
        }
    },
    created(){
        var all_trucks = this.$parent.groupByKey(this.$parent.project_parameters.filter(p => parseInt(p.type_id) == 2), 'arrives_at')
        this.all_trucks = {}
        if(all_trucks){
            Object.keys(all_trucks).map(t => {
                all_trucks[t] = [...all_trucks[t].map(tr => {
                    tr.is_transit = this.is_transit_warehouse(tr)
                    return tr
                })]
                this.all_trucks[t] = all_trucks[t]
                console.log('tttttttttttttttttttttttttttttttt', all_trucks[t])
            })
        }
    },
    methods: {
        get_warehouse_name(id){
            if(id){
                return this.$parent.$parent.warehouses.filter(w => w.Id == id)[0].Caption
            }
        },
        is_transit_warehouse(a){
            let is_transit = false
            this.$parent.transit_warehouses.map(t =>{
                console.log('ttttt', t)
                if(t.warehouse_id == parseInt(a.destination))
                    is_transit = true
            })
            return is_transit
        }
    },

}
</script>

<style>

</style>
